@import "../../styles/colors.scss";

.customization-page {
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;

  border-radius: 15px;

  margin-bottom: 2rem;

  background-color: rgba(158, 158, 158, 0.25);
  color: #fff;

  height: auto;
  width: 80rem;

  .character-phrase {
    width: 100%;
    height: 2rem;

    text-align: center;
    font-size: 2rem;
    font-weight: medium;

    margin: 2rem 0rem;

    > b {
      color: $satin-gold;
    }
  }
}
