@import "../../styles/colors.scss";

.pools-title {
  font-size: 1.5rem;
  font-weight: bold;

  margin: 2rem 0rem;
}

.pools-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // flex-direction: column;

  font-weight: bold;

  width: 100%;

  margin-bottom: 2rem;

  .pools-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    height: 40%;

    .adaptable-case-button,
    .intimidating-case-button,
    .lawful-case-button {
      font-size: 1.15rem;
      font-weight: bold;

      text-align: center;
      line-height: 0rem;

      background-color: $alice-blue;

      height: 2rem;
      min-width: 2.5rem;

      border: none;
      border-radius: 5px;

      padding: 0.5rem;
      margin: 0rem 0.5rem 0.5rem 0.75rem;
    }

    > span {
      font-size: 1.5rem;

      margin-bottom: 1.5rem;
    }

    > span + span {
      padding: 1.25rem;

      border: none;
      border-radius: 8px;

      background-color: $prussian;
    }

    .pools-button {
      font-size: 1.5rem;
      font-weight: bold;

      text-align: center;
      line-height: 0rem;

      background-color: $alice-blue;

      height: 2rem;
      width: 2rem;

      border: none;
      border-radius: 100%;

      margin: 0rem 0.5rem;

      cursor: pointer;

      transition: all 400ms ease-in-out;

      &:hover {
        background-color: $prussian;
        color: $alice-blue;
      }
    }

    .pools-reset-button {
      font-size: 1rem;

      text-align: center;

      background-color: $alice-blue;

      font-weight: bold;

      border: none;
      border-radius: 8px;

      padding: 0.5rem;

      cursor: pointer;

      transition: all 400ms ease-in-out;

      &:hover {
        background-color: $prussian;
        color: $alice-blue;
      }
    }
  }
}

.edges-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  font-size: 1.5rem;

  width: 100%;

  margin: 2rem;

  > b {
    margin-top: 1rem;
  }

  .edges-title {
    font-weight: bold;
  }

  .edges-wrapper {
    display: flex;
    justify-content: space-evenly;

    .edges-children {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      margin: 1rem;

      font-weight: bold;

      > span + span {
        display: inline-block;

        padding: 1rem;
        margin-top: 1.5rem;

        border: none;
        border-radius: 8px;

        width: 4rem;
        height: 4rem;

        text-align: center;

        background-color: $prussian;
      }

      .edge-button {
        font-size: 1.5rem;
        font-weight: bold;

        text-align: center;
        line-height: 0.5rem;

        background-color: $alice-blue;

        height: 2rem;
        width: 2rem;

        border: none;
        border-radius: 100%;

        margin: 1rem 0.5rem 0rem 0.5rem;

        cursor: pointer;

        transition: all 400ms ease-in-out;

        &:hover {
          background-color: $prussian;
          color: $alice-blue;
        }
      }
    }
  }
}
