.main-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url("../data/images/numenera-background.jpg") center center / cover, #333;
  background-attachment: fixed;

  min-height: 100vh;
  height: 100%;
  width: 100%;

  overflow-x: hidden;

  .trademark {
    position: relative;
    bottom: 0;

    font-weight: medium;
    color: #fff;

    margin-bottom: 2rem;

    @media (max-width: 810px) {
      margin-bottom: 4rem;
    }
  }
}
