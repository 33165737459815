.skills-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  font-size: 1.25rem;

  width: 100%;

  margin: 2rem;

  .skills-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .skills-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .skills-children {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      flex: 1;

      margin: 1rem 1.5rem;

      max-width: 45%;

      > span {
        font-weight: bold;
      }

      > ul li {
        list-style: square;
        margin-bottom: 0.75rem;
      }
    }
  }
}
