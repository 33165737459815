* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  font-family: "Noto Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}