@import "../../styles/colors.scss";

.abilities-container {
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  font-size: 1.25rem;

  margin: 2rem;

  .abilities-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .abilities-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .abilities-children {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      margin: 1rem 1.5rem;

      max-width: 50%;

      .abilities-fixed {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        > span {
          background-color: $dark-purple;

          border-radius: 15px;

          margin: 0.5rem;
          padding: 0.5rem;
        }
      }

      .abilities-choice,
      .abilities-selected {
        display: flex;
        flex-wrap: wrap;

        > span {
          background-color: grey;

          border-radius: 15px;

          max-width: 70%;

          text-align: center;

          margin: 0.5rem;
          padding: 0.5rem;

          cursor: pointer;

          transition: all 400ms ease-in-out;

          &:hover {
            background-color: $prussian;
          }
        }
      }

      .abilities-selected {
        > span {
          background-color: $indigo;
        }
      }
    }
  }
}
