.additional-container {
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  font-size: 1.25rem;

  margin: 2rem;

  .additional-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .additional-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .additional-children {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      margin: 1rem 1.5rem;

      max-width: 50%;

      > ul li {
        list-style-type: square;
      }

      > b {
        margin-top: 1rem;
      }
    }
  }
}
