@import "../../styles/colors.scss";

@mixin content {
  display: flex;
  align-items: center;
  flex-direction: column;

  border-radius: 15px;

  margin-bottom: 2rem;

  font-size: 1.25rem;

  background-color: rgba(158, 158, 158, 0.25);
  color: #fff;

  height: auto;
  width: 80rem;
}

.get-started {
  @include content;

  height: 8rem;

  justify-content: center;

  > a {
    padding: 1rem;

    text-decoration: none !important;

    color: #fff !important;

    background-color: $prussian !important;

    border: none;
    border-radius: 8px;

    transition: all 400ms ease-in-out;

    &:visited {
      color: white;
    }

    &:hover {
      background-color: $alice-blue !important;
      color: #000 !important;
    }
  }
}

.copyright-container {
  @include content;

  min-height: 100vh;

  h1 {
    margin: 1rem 0rem;
  }

  > p {
    max-width: 80%;
    margin-bottom: 1rem;

    > a {
      color: $munsell;

      text-decoration: none;

      &:visited {
        color: $pale-silver;
      }
    }
  }
}
