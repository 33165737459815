@import "./colors.scss";

div.generator {
  @media (max-width: 1325px) {
    height: 100%;
    width: 95%;

    h1 {
      display: none;
    }
  }

  @media (max-width: 810px) {
    height: 100%;
    min-height: 31rem;

    h1 {
      display: block;

      font-size: 1.25rem;
      padding: 1rem;
      text-align: center;
    }
  }

  > form {
    @media (max-width: 1325px) {
      flex-direction: column;

      // margin-bottom: 2rem;

      #descriptor,
      #type,
      #foci {
        width: 65%;
        text-align: center;
      }
    }

    @media (max-width: 810px) {
      #descriptor,
      #type,
      #foci {
        width: 100%;
        text-align: center;
      }
    }
  }
}

div.customization-page {
  @media (max-width: 1325px) {
    width: 95%;

    .character-phrase {
      font-size: 1.75rem;
    }
  }

  @media (max-width: 810px) {
    .character-phrase {
      font-size: 1.35rem;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      max-width: 95%;

      > b {
        margin: 0rem 0.35rem;
      }
    }

    .pools-container {
      flex-wrap: wrap;

      .pools-wrapper {
        margin: 1rem 2rem;
      }
    }

    .skills-children {
      min-width: 85%;

      > ul {
        align-self: flex-start;
      }
    }

    .abilities-children {
      min-width: 100%;
    }

    .additional-children {
      min-width: 100%;
    }
  }
}

nav.navbar {
  @media (max-width: 810px) {
    justify-content: center;

    position: fixed;
    bottom: 0;
    left: 0;

    background-color: #333;

    margin: 0rem;
    padding: 0.25rem;

    z-index: 1;

    .navbar-title {
      display: none;
    }

    .navbar-links a {
      margin: 1.5rem;
    }
  }
}

div.get-started {
  @media (max-width: 1325px) {
    width: 95%;

    margin-top: 2rem;
  }
}

div.copyright-container {
  @media (max-width: 1325px) {
    width: 95%;
  }
}

div.about-container {
  @media (max-width: 1325px) {
    width: 95%;
  }
}
