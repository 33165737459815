.divider {
  content: "";
  display: inline-block;

  width: 80%;
  height: 2px;

  vertical-align: middle;
  background-color: white;

  margin: 1rem;
}
