@import "../../styles/colors.scss";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  min-height: 2rem;
  width: 100%;

  margin: 1.5rem 0rem;

  color: #fff;
  font-weight: bold;

  > span + span {
    > a {
      text-decoration: none;

      margin: 0rem 3rem;

      color: #fff !important;

      transition: all 350ms ease-in-out;

      &:visited {
        color: #fff;
      }
    }
  }
}
